<template>
  <header id="appToolbar" class="min-h-16 max-w-screen bg-surface z-30 w-full">
    <div class="h-16 border-b border-neutral-200 px-4 py-2 lg:px-6">
      <div
        class="inline-flex h-full w-full flex-1 items-center justify-between"
      >
        <div class="h-full">
          <slot />
        </div>
        <div class="relative flex items-center justify-center space-x-4">
          <slot name="user-menu" />
        </div>
      </div>
    </div>
    <div id="toolbarActions" />
  </header>
</template>
